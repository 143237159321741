/* _component.select.scss */

.c-select {
    appearance: none;
    background-color: palette("white");
    background-image: url("img/ui/select-arrow.svg");
    background-position: center right ($space * 1.5);
    background-repeat: no-repeat;
    border: 1px solid palette("grey", 3);
    cursor: pointer;
    display: inline-block;
    padding-bottom: 7px;
    padding-left: space(2);
    padding-right: space(6);
    padding-top: 7px;
}

.c-select[disabled] {
    background-color: rgba(palette("grey", 2), 0.1);
}
