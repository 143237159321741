/* _component.label.scss */

.c-label {
    color: palette("text");
    display: block;
    font-size: 12px;
    margin-bottom: ($space * 0.5);
}

    .c-label--inline {
        display: inline-block;
        padding-right: $space;
    }
