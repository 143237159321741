/* _utility.position.scss */

.u-relative {
	position: relative;
}

.u-absolute-full-width {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    img {
        width: 100%;
    }
}
