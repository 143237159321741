/* _component.comments.scss */

.c-comments {

}

    .c-comments__insert {
        align-items: flex-start;
        display: flex;
        margin-bottom: space(3.5);
    }

    .c-comments__img {
        border: 1px solid palette("border");
        border-radius: 100%;
        height: 40px;
        width: 40px;
    }

    .c-comments__box {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: space(2);
    }

    .c-comments__input {
        @include rem(font-size, 16);
        border: 1px solid #BFBFBF;
        border-radius: 4px;
        color: palette("black");
        min-height: 40px;
        overflow: hidden;
        padding: space(1) space(1.5);
        resize: none;

        &.activeTextarea {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &::placeholder {
            @include rem(font-size, 14px);
            color: palette("grey", 2);
        }
    }

    .c-comments__bar {
        align-items: center;
        background-color: #F2F2F2;
        border-radius: 0 0 4px 4px;
        border: 1px solid #BFBFBF;
        border-top: none;
        display: flex;
        justify-content: space-between;
        height: 0;
        opacity: 0;
        overflow: hidden;
        padding: space(1) space(1.5);
        transition: all 0.2s ease-in-out;

        &.activeUpload {
            height: 57px;
            opacity: 1;
        }

        button {
            @include rem(font-size, 14px);
            align-items: center;
            display: flex;
            height: 40px;
            padding: 0 space(3);

            p {
                margin-left: space(0.5);
            }
        }

        img {
            cursor: pointer;
        }
    }

    .c-comment__wrap {
        .replies_outer {
            //background-image: url("img/ui/icon-reply.svg");
            background-position: left space(2) top space(1);
            background-repeat: no-repeat;
            .replies_outer {
                background: none;
            }
            &.indent {
                padding-left: space(7);
            }
            .c-comments__user {
                p:first-child {
                    &::after {
                        //content: url("img/ui/icon-user-reply.svg");
                        right: -15px;
                        position: absolute;
                        top: 0;
                    }
                }
            }
        }
    }

    .c-comments__comment {
        align-items: flex-start;
        display: flex;
        margin-bottom: space(4);

        &.replies {
            align-items: flex-end;
            display: flex;
            min-height: space(4);

            p {
                cursor: pointer;
            }
        }

        .c-comments__text {
            white-space: pre-line;

            a {
                color: #47ABC0;
            }
        }
    }

    .c-comments__content {
        margin-left: space(2);
    }

    .c-comments__user {
        display: flex;

        p {
            @include rem(font-size, 13px);
            color: #A9A9A9;
            margin-top: space(1.25);
            padding-left: space(2.25);
            position: relative;

            &:first-child {
                color: palette("blue");
                font-weight: 700;
                padding-left: 0;
            }

            &:last-child {
                &::before {
                    background-color: #A9A9A9;
                    border-radius: 100%;
                    content: "";
                    height: 3px;
                    left: space(1);
                    position: absolute;
                    top: space(1);
                    width: 3px;
                }
            }
        }
    }

    .c-comments__cta {
        align-items: center;
        display: flex;

        .liked {
            path {
                fill: palette("red");
                stroke: palette("red");
            }
        }

        .like {
            cursor: pointer;

            &:hover {
                fill: palette("red");
            }
        }

        div {
            align-items: center;
            display: flex;
            padding-left: space(2.25);
        }

        p {
            @include rem(font-size, 14px);
            font-weight: 600;
            padding-left: space(0.75);
            position: relative;

            &:first-child {
                padding-left: 0;

                &::after {
                    background-color: #A9A9A9;
                    border-radius: 100%;
                    content: "";
                    height: 3px;
                    right: -11px;
                    position: absolute;
                    top: space(1);
                    width: 3px;
                }
            }

            //&:last-child {
            //    &::after {
            //        content: url("img/ui/icon-user-likes.svg");
            //        right: -10px;
            //        position: absolute;
            //        top: -1px;
            //    }
            //}
        }
    }

    .c-comments__photo {
        border: 1px solid #BFBFBF;
        border-top: none;
        padding: 0 space(1.5) space(3);
    }

    .typeahead {
        margin-top: 2px;
        z-index: 1051;
    }

    .dropdown-menu {
        background-clip: padding-box;
        background-color: #ffffff;
        border: 1px solid #BFBFBF;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        display: none;
        float: left;
        left: 0;
        list-style: none;
        margin: -1px 0;
        max-width: 275px;
        padding: space(1) space(1) space(2);
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1000;

        li {
            border-bottom: 1px solid #BFBFBF;
            padding: 8px;

            &:hover {
                background: rgba(32, 115, 146, 0.15);
                border-radius: 6px;
            }
        }

        a {
            @include rem(font-size, 14px);
            align-items: center;
            display: flex;
        }

        .mention_image {
            border: 1px solid palette("border");
            border-radius: 100%;
            margin-right: space(1);
            max-width: 24px;
        }

        .mention_username {
            color: palette("grey", 2);
            margin-left: space(1);

            strong {
                font-weight: 400;
            }
        }
    }
