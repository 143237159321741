/* _utility.basis.scss */

/**
 * requires css var `--fixed`. can be set inline.
 * e.g. style="--fixed: 100px;"
 */
 .u-basis--fixed {
    flex-basis: var(--fixed);
    flex-grow: 0;
    flex-shrink: 0;
}


/**
 * requires css var `--fixed`. can be set inline.
 * e.g. style="--min: 100px;"
 */
.u-basis--min {
    flex-basis: var(--min);
    flex-shrink: 0;
}


/**
 * requires css var `--max`. can be set inline.
 * e.g. style="--max: 100px;"
 */
.u-basis--max {
    flex-basis: var(--max);
    flex-grow: 0;
}


/**
 * requires css var `--min` and `--max`. can be set inline.
 * e.g. style="--min: 100px; --max: 200px;"
 */
.u-basis--minmax {
    flex-basis: auto;
    flex-grow: 1;
    max-width: var(--max);
    min-width: var(--min);
}