.c-ugc {
    .hero-upload {
        position: static;
        height: 474px;
    }
    .hero-wrapper {
        position: absolute;
        height: 457px;
        left: 0;
        margin: 0 auto;
        right: 0;
        width: 813px;
    }
    .hero-upload label {
        display: block;
        .hero-box {
            align-items: center;
            border: 1px solid #888;
            display: flex;
            height: 457px;
            overflow: hidden;
            position: relative;
            width: 813px;
            img {
                width: 100%;
            }
            .remove {
                background-color: #fff;
                border: 1px solid #aaa;
                opacity: 0.5;
                position: absolute;
                right: space(0.5);
                top: space(0.5);
                img {
                    padding: space(0.5);
                }
            }
            .uploading {
                background-color: #fff;
                border: 1px solid #eee;
                border-radius: 5px;
                bottom: 5px;
                font-size: 12px;
                height: 36px;
                opacity: 0.5;
                position: absolute;
                right: 5px;
                width: 108px;
                .content {
                    padding-left: 5px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .upload-area {
        align-items: center;
        border: 1px solid #888;
        color: #ccc;
        display: flex;
        flex-direction: column;
        height: 457px;
        justify-content: center;
        img {
            margin-bottom: space(0.5);
        }
    }
    .hero-buttons {
        display: flex;
        justify-content: flex-end;
        .c-btn {
            margin: space(1);
            padding: 8px space(4);
        }
    }
    @include media(m) {
        .hero-wrapper {
            width: 100%;
        }
        .hero-upload label {
            .hero-box {
                position: relative;
                width: 100%;
            }
        }
        .upload-area {
            img {
                margin: 0 space(2);
            }
        }
    }
    .editor-content {
        margin: 0 auto;
        position: static;
        width: 634px;
    }
    figure.image {
        display: block;
        img {
            width: 100%;
        }
        &.image-style-align-center {
            display: table;
            width: 75%;
            img {
                width: auto;
            }
        }
        &.image-style-align-left {
            float: left;
            width: 50%;
            img {
                width: auto;
            }
        }
        &.image-style-align-right {
            float: right;
            width: 50%;
            img {
                width: auto;
            }
        }
    }

    .ck-content {
        margin-bottom: space(6);
        word-break: break-word;
        word-wrap: break-word;
        p {
            color: palette("text");
            font-size: 16px;
            margin-top: space(4);

            &:first-child {
                margin-top: 0;
            }
        }

        @include media(m) {
            margin-bottom: space(4);
        }

        a {
            color: palette("blue");
        }

        .image > figcaption {
            display: block;
        }
    }
    .contribute-indicator {
        border: 1px solid #000;
        border-radius: 18px;
        display: block;
        font-size: 24px;
        position: relative;
        left: -40px;
        text-align: center;
        top: 30px;
        visibility: hidden;
        width: 36px;
        &.active {
            visibility: visible;
        }
    }
}
