/* _component.video-wrapper.scss */

.c-video-wrapper {
	height: 0;
	min-height: 184px;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	position: relative;

    iframe {
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.c-video-wrapper__custom {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    padding-top: 56.25%;

    iframe {
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
