//========================================
//=            Media/Breakpoint          =
//========================================
@mixin media($media-query) {
    $breakpoint-found: false;

    // Loop and check our pre-defined breakpoints
    @each $breakpoint in $breakpoints {
        $name: nth($breakpoint, 1);
        $declaration: nth($breakpoint, 2);

        @if $media-query == $name and $declaration {
            $breakpoint-found: true;

            @media only screen and #{$declaration} {
                @content;
            }
        }
    }

    // If not in our pre-defined breakpoints lets output the media query
    @if not $breakpoint-found {
        // Exception catcher
        @if (not str-index($media-query, "(")) {
            @warn "`#{$media-query}` is not correct.";
        }
        @else {
            @media only screen and #{$media-query} {
                @content;
            }
        }
    }
}
