// SCSS Standard Build File

// Config
@import "_config.type",
        "_config.global",
        "_config.grid",
        "_config.space",
        "_config.colors";

// Tools
@import "_tools.clearfix",
        "_tools.media",
        "_tools.palette",
        "_tools.rem",
        "_tools.round",
        "_tools.space",
        "_tools.strip-units",
        "_tools.text-color",
        "_tools.widths";

// Objects
/*@import "_object.blocks",
        "_object.card",
        "_object.crop",
        "_object.media",
        "_object.ratio";*/

// Components
@import //"_component.404",
        //"_component.accreditations",
        //"_component.accordion",
        //"_component.advertisement",
        //"_component.breadcrumbs",
        "_component.checkbox",
        "_component.comments",
        //"_component.cta",
        "_component.empty-state",
        //"_component.hr",
        "_component.label",
        //"_component.modal",
        "_component.private",
        //"_component.radio",
        "_component.select",
        //"_component.table",
        //"_component.tabs",
        "_component.ugc",
        "_component.video-wrapper";

// Themes
@import "_theme.home";

// Vendors
@import "_vendor.slick",
        "_vendor.medium-editor",
        "_vendor.medium-editor-insert-plugin",
        "_vendor.fileupload";

// Utilities
@import "_utility.backgrounds",
        "_utility.basis",
        //"_utility.clearfix",
        "_utility.cursor",
        //"_utility.floats",
        //"_utility.lists",
        //"_utility.overflow",
        "_utility.position",
        //"_utility.pull",
        //"_utility.push",
        //"_utility.rotate",
        //"_utility.text-decoration",
        "_utility.text-transform",
        "_utility.underline";

// Necessary evil leave this last
@import "_utility.print";
