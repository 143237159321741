/* _component.checkbox.scss */

.c-checkbox {
	display: inline-block;
	line-height: 1;
	vertical-align: middle;
}

	.c-checkbox input[type="checkbox"] {
		display: none;

		&:checked + .c-checkbox__trigger {
			border-color: palette("grey");
			background-image: url("img/ui/checkbox.svg");
		}
	}

	.c-checkbox__trigger {
		background-position: center;
		background-repeat: no-repeat;
		border-color: palette("grey");
		border-style: solid;
		border-width: 1px;
		cursor: pointer;
		display: inline-block;
		height: space(3);
		position: relative;
		width: space(3);
	}

	.c-checkbox__label {
		cursor: pointer;
		display: inline-block;
		margin-left: $space;
		vertical-align: text-bottom;
	}
