//
// Global colour palette
// stylelint-disable value-list-max-empty-lines
$palettes: (
	"black": (
		1: #222,
		2: #000,
		3: #979797
	),

	"blue": (
		1: #47ABC0,
		2: #3A5897,
		3: #02164A,
		4: #6178AA
	),

	"red-dark": (
		1: #670000
	),

	"red": (
		1: #DB2848,
		2: #F16D6F,
		3: #BF3234
	),

	"teal": (
		1: #3FC3D2
	),

	"grey": (
		1: #444,
		2: #888,
		3: #d8d8d8,
		4: #ebebeb,
		5: #dedede,
		6: #8C8C8C
	),

	"border": (
		1: #979797
	),

	"text":(
		1: #222,
	),

	"white": (
		1: #fdfdfd,
		2: #F7F7F7
	),

	"divider":(
		1: #D8D8D8
	),

);
