/* _component.private.scss */

.c-private {
    align-items: center;
    background-color: palette("white", 2);
    border: 1px solid palette("grey", 3);
    display: flex;
    margin-top: space(10);
    padding: space(3) space(7);

    @include media(m) {
        margin-top: space(3);
    }

    @include media(s) {
        flex-direction: column;
        padding: space(1) space(2);
    }

    div {
        padding-left: space(3);

        @include media(s) {
            text-align: center;
            padding-left: 0;
            padding-top: space(1);
        }

        h5 {
            padding-bottom: space(0.5);
        }

        p {
            @include media(m) {
                font-size: 14px;
            }
        }
    }
}
