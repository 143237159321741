@keyframes medium-editor-image-loading {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes medium-editor-pop-upwards {
    0% {
        opacity: 0;
        transform: matrix(0.97, 0, 0, 1, 0, 12);
    }
    20% {
        opacity: 0.7;
        transform: matrix(0.99, 0, 0, 1, 0, 2);
    }
    40% {
        opacity: 1;
        transform: matrix(1, 0, 0, 1, 0, -1);
    }
    100% {
        transform: matrix(1, 0, 0, 1, 0, 0);
    }
}

.medium-editor-anchor-preview {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    left: 0;
    line-height: 1.4;
    max-width: 280px;
    position: absolute;
    text-align: center;
    top: 0;
    visibility: hidden;
    word-break: break-all;
    word-wrap: break-word;
    z-index: 2000;
}

.medium-editor-anchor-preview a {
    color: #fff;
    display: inline-block;
    margin: 5px 5px 10px;
}

.medium-editor-anchor-preview-active {
    visibility: visible;
}

.medium-editor-dragover {
    background: #ddd;
}

.medium-editor-image-loading {
    animation: medium-editor-image-loading 1s infinite ease-in-out;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    height: 40px;
    width: 40px;
}

.medium-editor-placeholder {
    position: relative;
}

.medium-editor-placeholder::after {
    color: palette("grey", 2);
    content: attr(data-placeholder) !important;
    left: 0;
    margin: inherit;
    padding: inherit;
    position: absolute;
    top: 0;
    white-space: pre;
}

.medium-editor-placeholder-relative {
    position: relative;
}

.medium-editor-placeholder-relative::after {
    color: palette("grey", 2);
    content: attr(data-placeholder) !important;
    margin: inherit;
    padding: inherit;
    position: relative;
    white-space: pre;
}

.medium-toolbar-arrow-under::after, .medium-toolbar-arrow-over::before {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -8px;
    position: absolute;
    width: 0;
}

.medium-toolbar-arrow-under::after {
    border-width: 8px 8px 0;
}

.medium-toolbar-arrow-over::before {
    border-width: 0 8px 8px;
    top: -8px;
}

.medium-editor-toolbar {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    left: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: 3;
}

.medium-editor-toolbar ul {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.medium-editor-toolbar li {
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
}

.medium-editor-toolbar li button {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 1.33;
    margin: 0;
    padding: 15px;
    position: relative;
    text-decoration: none;
}

.medium-editor-toolbar li button:focus {
    outline: none;
}

.medium-editor-toolbar li .medium-editor-action-underline {
    text-decoration: underline;
}

.medium-editor-toolbar li .medium-editor-action-pre {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    font-weight: 100;
    padding: 15px 0;
}

.medium-editor-toolbar-active {
    visibility: visible;
}

.medium-editor-sticky-toolbar {
    position: fixed;
    top: 1px;
}

.medium-editor-relative-toolbar {
    position: relative;
}

.medium-editor-toolbar-active.medium-editor-stalker-toolbar {
    animation: medium-editor-pop-upwards 160ms forwards linear;
}

.medium-editor-action-bold {
    font-weight: bolder;
}

.medium-editor-action-italic {
    font-style: italic;
}

.medium-editor-toolbar-form {
    display: none;
}

.medium-editor-toolbar-form input,
.medium-editor-toolbar-form a {
    font-family: Helvetica, Arial, sans-serif;
}

.medium-editor-toolbar-form .medium-editor-toolbar-form-row {
    line-height: 14px;
    margin-left: 5px;
    padding-bottom: 5px;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input,
.medium-editor-toolbar-form label {
    border: none;
    box-sizing: border-box;
    font-size: 14px;
    margin: 0;
    padding: 6px;
    width: 316px;
    display: inline-block;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:focus,
.medium-editor-toolbar-form label:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
    outline: 0;
}

.medium-editor-toolbar-form a {
    display: inline-block;
    font-size: 24px;
    font-weight: bolder;
    margin: 0 10px;
    text-decoration: none;
}

.medium-editor-toolbar-form-active {
    display: block;
}

.medium-editor-toolbar-actions::after {
    clear: both;
    content: "";
    display: table;
}

.medium-editor-element {
    word-wrap: break-word;
    min-height: 30px;
}

.medium-editor-element img {
    max-width: 100%;
}

.medium-editor-element sub {
    vertical-align: sub;
}

.medium-editor-element sup {
    vertical-align: super;
}

.medium-editor-hidden {
    display: none;
}

.medium-toolbar-arrow-under::after {
    border-color: #000 transparent transparent;
    top: 46px;
}

.medium-toolbar-arrow-over::before {
    border-color: transparent transparent #000;
}

.medium-editor-toolbar {
    background-color: #000;
    border: none;
    border-radius: 10px;
    padding: 1px;
}

.medium-editor-toolbar li button {
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: #ccc;
    height: 40px;
    min-width: 52px;
    padding: 5px 12px;
    transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.medium-editor-toolbar li button:hover {
    background-color: #000;
    color: #a2d7c7;
}

.medium-editor-toolbar li .medium-editor-button-first {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    padding-left: 24px;
}

.medium-editor-toolbar li .medium-editor-button-last {
    border-bottom-right-radius: 50px;
    border-right: none;
    border-top-right-radius: 50px;
    padding-right: 24px;
}

.medium-editor-toolbar li .medium-editor-button-active {
    svg {
        fill: palette("red");
    }
}

.medium-editor-toolbar-form {
    background: #000;
    border-radius: 50px;
    color: #ccc;
    overflow: hidden;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input {
    background: #000;
    box-sizing: border-box;
    color: #ccc;
    height: 40px;
    padding-left: 16px;
    width: 220px;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-webkit-input-placeholder {
    color: rgba(248, 245, 243, 0.8);
}

.medium-editor-toolbar-form a {
    color: #ccc;
    transform: translateY(2px);
}

.medium-editor-toolbar-form .medium-editor-toolbar-close {
    margin-right: 16px;
}

.medium-editor-toolbar-anchor-preview {
    background: #000;
    border-radius: 50px;
    padding: 5px 12px;
}

.medium-editor-anchor-preview a {
    color: #ccc;
    text-decoration: none;
}

.medium-editor-toolbar-actions li, .medium-editor-toolbar-actions button {
    border-radius: 50px;
}

.medium-editor-loader {
    align-items: center;
    background-color: palette("white");
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 3;
}

.c-editable-content__img-wide, .c-editable-content__img-center, .c-editable-content__img-full {
    clear: both;
}
